import React, {useCallback, useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";

function App() {
    const ms = 60 * 60 * 1000;

    const [remainingMs, setRemainingMs] = useState(ms);

    useEffect(() => {

        console.log(`pages will be refreshed in ${ms} ms`)
        setTimeout(function () {
            console.log('refreshed')
            window.location.reload();
        }, ms);
    }, []);

    useEffect(()=>{
        const timer =
            remainingMs > 0 && setInterval(() => setRemainingMs(remainingMs - 1000), 1000);
        // @ts-ignore
        return () => clearInterval(timer);
    },[remainingMs])

    const [searchParams, setSearchParams] = useSearchParams();

    const Iframe = useCallback(() => {
        return (<iframe className='w-full h-full' src={`getMampf/?password=${searchParams.get('password')}`}
        />)
    }, []);

    return (
        <>
            <div className='flex flex-col h-full'>
                <div className='flex flex-row justify-between'>
                    <h1 className="text-3xl font-bold">
                        What-The-Mampf...?
                    </h1>
                    <h1 className="text-3xl font-bold text-red-900">
                        refresh in {remainingMs / 1000}
                    </h1>
                </div>
                <Iframe/>
            </div>
        </>
    );
}

export default App;
